import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BrowserRoutes from "../routes/browserRoutes";
const Profile = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate(BrowserRoutes.LOGIN);
    }, 2000);
  }, []);
  return (
    <div class="wrapper">
      <div class="wrapper-inner">
        <div class="profile-page "></div>
      </div>
    </div>
  );
};

export default Profile;
