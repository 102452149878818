import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BrowserRoutes from "../routes/browserRoutes";
import Start from "./start";
import Profile from "./profile";
var newloading = 0;
const Loading = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(0);
  const [indexpage, setIndexPage] = useState(true);
  const [profilepage, setProfilePage] = useState(false);
  useEffect(() => {
    if (indexpage) {
      setTimeout(() => {
        setIndexPage(false);
      }, 2000);
    } else {
      const Interval = setInterval(() => {
        if (loader < 100) {
          newloading = newloading + 6;
          setLoader(newloading);
        }
      }, 120);
      if (loader >= 100) {
        setProfilePage(true);
      }
      return () => {
        clearInterval(Interval);
      };
    }
  }, [loader, indexpage]);

  return indexpage ? (
    <Start />
  ) : profilepage ? (
    <Profile />
  ) : (
    <div class="wrapper wrapper4">
      <div class="wrapper-inner">
        <div class="loading-content pt-5">
          <div class="loading-img text-center mb-2">
            <img loading="eager"
              src="assests/images/Fun-game-Asia-logo.png"
              alt="Fun-game-Asia-logo"
            />
          </div>
          <div class="row g-0">
            <div class="col-md-5 col-8 mx-auto text-center">
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style={{ width: `${loader + 20}%` }}
                  aria-valuenow={loader}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <span class="loading-text mt-10">Loading {loader}% . . .</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
