import axios from 'axios';
import ServerRoutes from '../../routes/serverRoutes';
import { setNumber } from '../slices/spinnerSlice';
import { store } from '../store/store';
export const spinnerRequest = (body) => (dispatch) => {
  let id = store.getState().auth.user.id;
  axios
    .get(ServerRoutes.SPINNER + id)
    .then((res) => {
      console.log('testing spinner number ' + res.data.number);
      return dispatch(setNumber(res.data.number));
    })
    .catch(() => {
      dispatch(setNumber(null));
    });
};
