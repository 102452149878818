import React from "react";

const Start = () => {
  return (
    <div class="wrapper2">
      <div class="wrapper-inner">
        <div class="first-page position-relative ">
          <div class="container">
            <div class="row">
              <div class="col-12 mt-50 mx-auto text-md-start text-center">
                <img loading="eager"
                  src="assests/images/Fun-game-Asia-logo.png"
                  class="first-img img-fluid"
                  alt="Fun-game-Asia-logo"
                />
              </div>
              <p class=" loading">loading . . .</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Start;
