import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loggedIn: false,
  user: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearState: (state, action) => {
      state.loggedIn = false;
      state.user = {};
    },
  },
});

export const { setLoggedIn, setUser, clearState } = authSlice.actions;

export default authSlice.reducer;
