import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logoutRequest } from '../redux-toolkit/actions/auth';
import { useNavigate } from 'react-router-dom';
import BrowserRoutes from '../routes/browserRoutes';
import { Link } from 'react-router-dom';
import useSound from 'use-sound';
const notification = require('../sounds/screenchange.mp3');
const Account = () => {
  const [play] = useSound(notification);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [profile, setProfile] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setProfile(false);
    }, 1500);
  }, []);

  return profile ? (
    <div class='wrapper'>
      <div class='wrapper-inner'>
        <div class='profile-page '></div>
      </div>
    </div>
  ) : (
    <div class='wrapper wrapper5'>
      <div class='wrapper-inner'>
        <div class='account-content '>
          <div class='account-content-container'>
            <div class='row account-contentrow pt-20 align-items-center'>
              <div class='col-lg-2 col-6 text-center col-sm-3 position-relative'>
                <img loading="eager"
                  src='assests/images/Asset-3.png'
                  class='points-bg img-fluid'
                  alt='Asset 3'
                />
                <span class='position-absolute points'>0</span>
              </div>
              <div class='col-lg-2 col-6 col-sm-3 position-relative'>
                <img loading="eager"
                  src='assests/images/Asset-4.png'
                  class='user img-fluid'
                  alt='Asset 4'
                />
                <span class='position-absolute user-name'>
                  FUN{user.user_id}
                </span>
              </div>
              <div class='col-lg-4 col-sm-4 d-none d-lg-flex justify-content-center'>
                <a href='#'>
                  <img loading="eager"
                    src='assests/images/Fun-game-Asia-logo.png'
                    class='img-fluid account-logo'
                    alt='Fun-game-Asia-logo'
                  />
                </a>
              </div>
              <div class='col-12 d-sm-none text-center mt-20 mb-20'>
                <a href='#'>
                  <img loading="eager"
                    src='assests/images/Fun-game-Asia-logo.png'
                    class='img-fluid account-logo'
                    alt='Fun-game-Asia-logo'
                  />
                </a>
              </div>
              <div class='col-lg-2 col-sm-3 col-6 position-relative'>
                <a href='#'>
                  <img loading="eager"
                    src='assests/images/My-Account-Strip.png'
                    class='img-fluid account-stripe'
                    alt='My Account Strip.png'
                  />
                  <span class='position-absolute my-account d'>My Account</span>
                </a>
              </div>
              <div class='col-lg-2 col-sm-3 col-6 position-relative'>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(logoutRequest(navigate));
                  }}
                >
                  <img loading="eager"
                    src='assests/images/Logout-Strip.png'
                    class='img-fluid account-img'
                    alt='Logout Strip'
                  />
                  <span class='position-absolute my-account d'>Logout</span>
                </span>
              </div>
            </div>
            <div class='row mt-20 d-none d-sm-block d-lg-none'>
              <div class='col-lg-4 mx-auto col-md-4 d-flex justify-content-center'>
                <a href='#'>
                  <img loading="eager"
                    src='assests/images/Fun-game-Asia-logo.png'
                    class='img-fluid account-logo'
                    alt='Fun-game-Asia-logo'
                  />
                </a>
              </div>
            </div>
            <div class='d-none d-sm-block'>
              <div class='row  game-listrow g-0 row-cols-lg-5 text-center justify-content-center'>
                <div class='col'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/Fun-Sorat-Timer.png'
                            alt='Fun Sorat Timer'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class='col'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <Link onClick={() => play()} to={BrowserRoutes.GAME}>
                          <img loading="eager"
                            src='assests/images/Fun-Target-Timer.png'
                            alt='Fun Target Timer'
                            class='game-listimg'
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class='col'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/American-Roulette.png'
                            alt='American Roulette'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class='col'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/Eropean-Rolette-timer.png'
                            alt='Eropean Rolette timer'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class='col'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/fun-Matka-timer.png'
                            alt='fun Matka timer'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class='d-none d-sm-block'>
              <div class='row game-listrow row-cols-lg-5  text-center justify-content-center'>
                <div class='col'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/Fun-Card.png'
                            alt='Fun Card'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class='col'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/Fun-Target.png'
                            alt='Fun Target'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class='col'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/American-Roulette.png'
                            alt='American Roulette Timer'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class='col'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/european-Roulette.png'
                            alt='european Roulette'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class='col'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/Fun-matka.png'
                            alt='Fun matka'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class='d-sm-none'>
              <div class='row mt-20'>
                <div class='col-4'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/Fun-Sorat-Timer.png'
                            alt='Fun-Sorat-Timer'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class='col-4'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/Fun-Target-Timer.png'
                            alt='Fun Target'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class='col-4'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/American-Roulette-Timer.png'
                            alt='American Roulette Timer'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class='d-sm-none'>
              <div class='row mt-20'>
                <div class='col-4'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/Eropean-Rolette-timer.png'
                            alt='Eropean Rolette timer'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class='col-4'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/fun-Matka-timer.png'
                            alt='fun Matka timer'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class='col-4'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/Fun-Card.png'
                            alt='Fun Card'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class='d-sm-none mt-20'>
              <div class='row'>
                <div class='col-4'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/Fun-Target.png'
                            alt='Fun Target'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class='col-4'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/American-Roulette-Timer.png'
                            alt='American Roulette Timer'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class='col-4'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/european-Roulette.png'
                            alt='european Roulette'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class='d-sm-none mt-20'>
              <div class='row'>
                <div class='col-4 mx-auto mx-sm-0'>
                  <div class='game-list'>
                    <ul>
                      <li class='text-center'>
                        <a href='#'>
                          <img loading="eager"
                            src='assests/images/Fun-matka.png'
                            alt='Fun matka.png'
                            class='game-listimg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
