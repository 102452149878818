import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  number: 0,
};

export const spinnerSlice = createSlice({
  name: "spinner",
  initialState,
  reducers: {
    setNumber: (state, action) => {
      state.number = action.payload;
    },
  },
});

export const { setNumber } = spinnerSlice.actions;

export default spinnerSlice.reducer;
