import axios from "axios";
import ServerRoutes from "../../routes/serverRoutes";
import { setLoggedIn, setUser, clearState } from "../slices/authSlice";
import toast from "react-hot-toast";
import BrowserRoutes from "./../../routes/browserRoutes";
export const LoginRequest = (body) => (dispatch) => {
  axios.post(ServerRoutes.LOGIN, body).then((res) => {
    if (res.data.error) {
      toast.error(res.data.message);
    } else {
      dispatch(setLoggedIn(true));
      dispatch(setUser(res.data.user));
    }
  });
};

export const logoutRequest = (navigate) => (dispatch) => {
  dispatch(clearState()).then((a) => navigate(BrowserRoutes.LOGIN));
};
