import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/exports';
import toast from 'react-hot-toast';
import BrowserRoutes from '../../routes/browserRoutes';
const PrivateRoute = ({ component: Component }) => {
  const isAuthenticated = useSelector((state) => state.auth.loggedIn);
  useEffect(() => {
    return () => {
      if (!isAuthenticated)
        toast.error('You must be logged in to view this page');
    };
  }, []);
  if (isAuthenticated) return <Component />;
  else {
    return <Navigate to={BrowserRoutes.LOGIN} />;
  }
};

export default PrivateRoute;
