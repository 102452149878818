import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import { useDispatch } from "react-redux";
import { LoginRequest } from "../redux-toolkit/actions/auth";
import toast from "react-hot-toast";
import useSound from "use-sound";
const notification = require("../sounds/screenchange.mp3");

const Login = () => {
  const [play] = useSound(notification);
  const dispatch = useDispatch();
  const [body, setBody] = useState({ user_id: "", password: "" });

  const login = () => {
    if (isValid(body)) {
      dispatch(LoginRequest(body));
      play();
    } else {
      toast.error("Kindly Fill out all the fields");
    }
  };
  const onChange = (e) => {
    setBody({ ...body, [e.target.name]: e.target.value });
  };
  return (
    <div class="wrapper wrapper4">
      <div class="wrapper-inner">
        <div class="login-page  pe-lg-20">
          <div class="row g-0 d-lg-none d-block">
            <div class="col-12">
              <div class="game-img position-absolute">
                <img loading="eager"
                  src="assests/images/Fun-game-Asia-logo.png"
                  alt="Fun-game-Asia-logo.png"
                />
              </div>
            </div>
          </div>
          <div class="row login-row g-0">
            <div class="col-lg-8 col-sm-12 position-relative">
              <div class="login-area">
                <form>
                  <Input
                    label={"Username:"}
                    type={"text"}
                    name={"user_id"}
                    value={body.user_id}
                    required={true}
                    onChange={onChange}
                  />
                  <br />
                  <div class="mb-20 password-area">
                    <Input
                      label={"Password:"}
                      type={"password"}
                      name={"password"}
                      value={body.password}
                      onChange={onChange}
                      required={true}
                    />
                  </div>

                  <div class="form-group">
                    <input type="checkbox" id="html" checked={true} />
                    <label for="html">Remember password</label>
                  </div>
                  <div class="login-register d-flex justify-content-center mt-50 mb-40">
                    <a href="#" class="me-30 me-xs-10" onClick={login}>
                      Login
                    </a>
                    <a href="#" class="opacity-5">
                      Register
                    </a>
                  </div>
                  <div class="password-pin d-flex justify-content-center">
                    <a href="#" class="me-30 me-xs-10">
                      Forgot password
                    </a>
                    <a href="#">Forgot PIN</a>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-4 d-none d-lg-block align-self-end">
              <div class="game-img  ">
                <img loading="eager"
                  src="assests/images/Fun-game-Asia-logo.png"
                  alt="Fun-game-Asia-logo.png"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="position-absolute cross text-end text-lg-start  ms-auto mt-20">
            <a href="#">
              <img loading="eager"
                src="assests/images/Asset-2.png"
                alt="Asset-2"
                class="red-cross img-fluid"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

const isValid = (obj) => {
  if (obj.user_id && obj.password) return true;
  else return false;
};
