import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthRoute from "./components/PrivateRoutes/authRoute";
import PrivateRoute from "./components/PrivateRoutes/privateRoute";
import NotFound from "./pages/NotFound";
import BrowserRoutes from "./routes/browserRoutes";
import { Toaster } from "react-hot-toast";
import Login from "./pages/login.js";
import Loading from "./pages/loading.js";
import Account from "./pages/account";
import Game from "./pages/game";
function App() {
  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <Router>
        <Routes>
          <Route path={BrowserRoutes.LOADING} element={<Loading />}></Route>
          <Route
            path={BrowserRoutes.LOGIN}
            element={<AuthRoute component={Login} />}
          ></Route>
          <Route
            path={BrowserRoutes.ACCOUNT}
            element={<PrivateRoute component={Account} />}
          ></Route>
          <Route
            path={BrowserRoutes.GAME}
            element={<PrivateRoute component={Game} />}
          ></Route>
          <Route path={BrowserRoutes.NOT_FOUND} element={<NotFound />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
