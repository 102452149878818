import React, { useEffect, useState } from 'react';
import { spinnerRequest } from '../redux-toolkit/actions/spinner';
import { useDispatch, useSelector } from 'react-redux';
import img1 from '../images/1.png';
import img2 from '../images/2.png';
import img3 from '../images/3.png';
import img4 from '../images/4.png';
import img5 from '../images/5.png';
import img6 from '../images/6.png';
import img7 from '../images/7.png';
import img8 from '../images/8.png';
import useSound from 'use-sound';
import { Link } from 'react-router-dom';
import injectStyle from '../components/injectstyle';
const notification = require('../sounds/timers.mp3');
const notification2 = require('../sounds/clickcoin.mp3');
const notification3 = require('../sounds/spinSound.mp3');
const clearBtnSound = require('../sounds/clearbtn.mp3');
const prevDblBtnSound = require('../sounds/prevdoublebtn.mp3');

var index = 0;
const Game = () => {
  const [pause, setPause] = useState(false);
  document.addEventListener('visibilitychange', function () {
    if (document.hidden) {
      if (!pause) {
        setPause(true);
      }
    } else {
      if (pause) {
        setPause(false);
      }
    }
  });
  const [aname, setaname] = useState('');
  let spinn = {
    animationDuration: '6000ms',
    animationName: `${aname}`,
    animationTimingFunction: 'ease-out',
  };
  const clickHdl = (num) => {
    let styleSheet = document.styleSheets[index];
    let animationName = `spin${Math.round(Math.random() * 100)}`;
    let keyframes = `@keyframes ${animationName} {
        from {
            transform:rotate(${num}deg);
        }
        to {
            transform:rotate(${num + 1080}deg);
        }`;
    setaname(animationName);
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  };
  let images = [img1, img2, img3, img4, img5, img6, img7, img8, img5, img3];
  const spinNumber = useSelector((state) => state.spinner.number);
  const dispatch = useDispatch();
  const [play] = useSound(notification);
  const [play2] = useSound(notification2);
  const [play3, { stop }] = useSound(notification3);
  const [play4] = useSound(clearBtnSound);
  const [play5] = useSound(prevDblBtnSound);

  const [rotateStyle, setRotateStyle] = useState({
    transform: `rotate(36deg)`,
  });
  const style = {
    webkitBoxShadow: '-1px 9px 8px #efdf1f',
    borderRadius: '28px',
  };
  const user = useSelector((state) => state.auth.user);
  const [spinclass, setClass] = useState('');
  const [slideclass, setClassS] = useState('');
  const [timer, setTimer] = useState(60);
  const [selected, setSelected] = useState(0);
  const [number, setNumber] = useState(7);
  const [selectedImage, setSelectedImage] = useState(images[number]);
  const [clearclass, setClearClass] = useState('');
  const [numbers2, setNumbers2] = useState([]);
  let num = Math.floor(Math.random() * (9 - 0 + 1) + 0);
  const [numbers, setNumbers] = useState([
    Math.floor(Math.random() * (9 - 0 + 1) + 0),
    Math.floor(Math.random() * (9 - 0 + 1) + 0),
    Math.floor(Math.random() * (9 - 0 + 1) + 0),
    Math.floor(Math.random() * (9 - 0 + 1) + 0),
    Math.floor(Math.random() * (9 - 0 + 1) + 0),
    Math.floor(Math.random() * (9 - 0 + 1) + 0),
    Math.floor(Math.random() * (9 - 0 + 1) + 0),
    Math.floor(Math.random() * (9 - 0 + 1) + 0),
    Math.floor(Math.random() * (9 - 0 + 1) + 0),
    0,
  ]);

  useEffect(() => {
    if (!pause) {
      if (timer > 0) {
        setTimeout(() => {
          let time = timer - 1;
          setTimer(time);
          play();
        }, 1000);
      }
      if (timer === 10) {
        setClearClass('clr');
      }
      if (timer === 0) {
        console.log('spinNumber ', spinNumber);
        console.log('num ', num);
        clickHdl(spinNumber ? spinNumber * -36 + 36 : num * -36 + 36);
        if (spinNumber !== null) {
          setNumber(spinNumber);
          setSelectedImage(images[spinNumber - 1]);
          setRotateStyle({
            transform: `rotate(${spinNumber ? spinNumber * -36 + 36 : num * -36 + 36
              }deg)`,
          });
          const number3 = Math.floor(Math.random() * (8 - 0 + 1) + 0);
          setSelectedImage(images[number3]);
          setTimeout(() => {
            setTimeout(() => {
              setNumbers([...numbers, spinNumber]);
            }, 250);
            setNumbers2([
              ...numbers2,
              number3 === 0 ? 4 : number3 === 1 ? 2 : 1,
            ]);
            setClassS('');
          }, 6000);
        } else {
          setRotateStyle({
            transform: `rotate(${spinNumber ? spinNumber * -36 + 36 : num * -36 + 36
              }deg)`,
          });
          const number3 = Math.floor(Math.random() * (8 - 0 + 1) + 0);
          setSelectedImage(images[number3]);
          setTimeout(() => {
            setNumbers([...numbers, num]);
            setNumbers2([
              ...numbers2,
              number3 === 0 ? 4 : number3 === 1 ? 2 : 1,
            ]);
            setClassS('');
          }, 6000);
        }

        play3();

        setClass('spin');
        setClassS('slide');
        setTimer(60);
        setTimeout(() => {
          // setClass("spinSlow");
        }, 2000);
        setTimeout(() => {
          setClearClass('');
          setClass('');
          setClassS('');
        }, 9000);
      }
    }
  }, [timer, pause]);
  useEffect(() => {
    if (selected) {
      play2();
    }
  }, [selected]);

  useEffect(() => {
    timer === 2 && dispatch(spinnerRequest());
  }, [timer]);

  const btnTimer = 'assests/images/Timer.png';
  const btnTimerBlink = 'assests/images/Blink5.gif';

  const clearBtn = () => {
    play4();
  };

  const prevDblBtn = () => {
    play5();
  };

  return (
    <div
      class=' wrapper wrapper3'
      style={{
        display: 'flex',
      }}
    >
      <div class='wrapper-inner'>
        <div class='game-page pt-10 '>
          <div class='d-none d-sm-block'>
            <div class='top-content d-flex justify-content-between'>
              <div class='pass-id'>
                <span class='d-block'>ID: {user.user_id}</span>
                <span class='d-block'>P ID: 0</span>
              </div>
              <div class='fun-timer'>
                <img
                  loading='eager'
                  src='assests/images/Fun-(1).gif'
                  alt='Fun'
                />
              </div>
              <div class='cross'>
                <Link onClick={() => stop()} to='/account'>
                  <img
                    loading='eager'
                    src='assests/images/Asset-2.png'
                    alt='Asset 2'
                  />
                </Link>
              </div>
            </div>
          </div>
          <div class='d-sm-none'>
            <div class='top-content d-flex justify-content-between'>
              <div class='pass-id'>
                <span class='d-block'>ID: {user.user_id}</span>
                <span class='d-block'>P ID: 0</span>
              </div>
              <div class='cross'>
                <a href='#'>
                  <img
                    loading='eager'
                    src='assests/images/Asset-2.png'
                    alt='Asset 2'
                  />
                </a>
              </div>
            </div>
            <div class='fun-timer d-flex justify-content-center'>
              <img loading='eager' src='assests/images/Fun-(1).gif' alt='Fun' />
            </div>
          </div>
          {/* <!-- First row --> */}
          <div class='d-none d-xl-block coustom-style'>
            <div class='row pt-10 g-0'>
              <div class='col-md-3'>
                <ul class='mb-40'>
                  <li class='d-inline-block me-45'>
                    <a href='#' onClick={() => setSelected(1)}>
                      <img
                        loading='eager'
                        style={selected === 1 ? style : {}}
                        src='assests/images/1-Ruppes-Coin.png'
                        alt='1 Ruppes 1 Ruppes Coin'
                        class={`${clearclass} coin-img`}
                      />
                    </a>
                  </li>
                  <li class='d-inline-block me-45'>
                    <a href='#' onClick={() => setSelected(5)}>
                      <img
                        loading='eager'
                        style={selected === 5 ? style : {}}
                        src='assests/images/5-Ruppes-coin.png'
                        alt='5 Ruppes coin'
                        class={`${clearclass} coin-img`}
                      />
                    </a>
                  </li>
                  <li class='d-inline-block'>
                    <a href='#' onClick={() => setSelected(10)}>
                      <img
                        loading='eager'
                        style={selected === 10 ? style : {}}
                        src='assests/images/10-Ruppes-Coin.png'
                        alt='10 Ruppes Coin.png'
                        class={`${clearclass} coin-img`}
                      />
                    </a>
                  </li>
                </ul>
                <ul class='mb-40'>
                  <li class='d-inline-block  me-45'>
                    <a href='#' onClick={() => setSelected(50)}>
                      <img
                        loading='eager'
                        style={selected === 50 ? style : {}}
                        src='assests/images/50-Ruppes-Coin.png'
                        alt='50 Ruppes Coin'
                        class={`${clearclass} coin-img`}
                      />
                    </a>
                  </li>
                  <li class='d-inline-block me-45'>
                    <a href='#' onClick={() => setSelected(100)}>
                      <img
                        loading='eager'
                        style={selected === 100 ? style : {}}
                        src='assests/images/100-Ruppes.png'
                        alt='100 Ruppes coin'
                        class={`${clearclass} coin-img`}
                      />
                    </a>
                  </li>
                  <li class='d-inline-block'>
                    <a href='#' onClick={() => setSelected(500)}>
                      <img
                        loading='eager'
                        style={selected === 500 ? style : {}}
                        src='assests/images/500-Ruppes-Coin.png'
                        alt='500 Ruppes Coin.png'
                        class={`${clearclass} coin-img`}
                      />
                    </a>
                  </li>
                </ul>
                <ul>
                  <li class='d-inline-block me-45'>
                    <a href='#' onClick={() => setSelected(1000)}>
                      <img
                        loading='eager'
                        style={selected === 1000 ? style : {}}
                        src='assests/images/1000-Ruppes-Coin.png'
                        alt='1000 Ruppes Coin'
                        class={`${clearclass} coin-img`}
                      />
                    </a>
                  </li>
                  <li class='d-inline-block me-45'>
                    <a href='#' onClick={() => setSelected(5000)}>
                      <img
                        loading='eager'
                        style={selected === 5000 ? style : {}}
                        src='assests/images/5000-Ruppes.png'
                        alt='5000 Ruppes coin'
                        class={`${clearclass} coin-img`}
                      />
                    </a>
                  </li>
                  <li class='d-inline-block'>
                    <a href='#' onClick={() => setSelected(0)}>
                      <img
                        loading='eager'
                        src='assests/images/Delete-Button-Logo.png'
                        alt='Delete Button Logo'
                        class='coin-img'
                      />
                    </a>
                  </li>
                </ul>
                <ul class='blnc-bet-win mt-50'>
                  <li class='d-inline-block'>
                    <a href='#' class='position-relative'>
                      <img
                        loading='eager'
                        src='assests/images/Asset-33.png'
                        alt='Asset 33'
                        class='img-fluid'
                      />
                      <span class='position-absolute amount'>0</span>
                      <span class='position-absolute amount-desc'>BALANCE</span>
                    </a>
                  </li>
                  <li class='d-inline-block'>
                    <a href='#' class='position-relative'>
                      <img
                        loading='eager'
                        src='assests/images/Asset-33.png'
                        alt='Asset 33'
                        class='img-fluid'
                      />
                      <span class='position-absolute amount'>0</span>
                      <span class='position-absolute amount-desc'>
                        TOTAL BET
                      </span>
                    </a>
                  </li>
                  <li class='d-inline-block'>
                    <a href='#' class='position-relative'>
                      <img
                        loading='eager'
                        src='assests/images/Asset-33.png'
                        alt='Asset 33'
                        class='img-fluid'
                      />
                      <span class='position-absolute amount'>0</span>
                      <span class='position-absolute amount-desc'>WINNING</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class='col-md-6 text-center'>
                <div
                  class='wheel position-relative'
                  style={{
                    top: '-41px',
                    zoom: '117%',
                  }}
                >
                  <img
                    loading='eager'
                    src='assests/images/Wheel-Final.gif'
                    alt='Wheel-Final'
                    class='wheel-img img-fluid'
                  />
                  <div
                    style={{
                      padding: '15px',
                      top: '45px',
                      ...spinn,
                      ...rotateStyle,
                    }}
                    class={`wheel-inner position-absolute`}
                  >
                    <img
                      loading='eager'
                      src='assests/images/wheel-round2.png'
                      class='img-fluid'
                      alt='wheel-round'
                    />
                  </div>

                  <div class='wheel-middle position-absolute'>
                    <div style={{ top: '15px' }} class='position-relative'>
                      <img
                        loading='eager'
                        src='assests/images/Cent.png'
                        alt='Cent'
                        class='wheel-middle1'
                      />

                      <div
                        style={{
                          // backgroundColor: "red",
                          width: '100px',

                          overflow: 'hidden',
                        }}
                        class={`justify-content-end position-absolute money d-flex ${slideclass}`}
                      >
                        <span
                          className='d-flex'
                          style={{
                            height: '65px',
                          }}
                        >
                          <img
                            loading='eager'
                            src={img1}
                            alt='pngwing.com.png'
                            width={80}
                          />
                          <img
                            loading='eager'
                            src={img2}
                            alt='pngwing.com.png'
                            width={80}
                          />
                          <img
                            loading='eager'
                            src={img3}
                            alt='pngwing.com.png'
                            width={80}
                          />
                          <img
                            loading='eager'
                            src={img4}
                            alt='pngwing.com.png'
                            width={100}
                          />
                          <img
                            loading='eager'
                            src={img5}
                            alt='pngwing.com.png'
                            width={100}
                          />
                          <img
                            loading='eager'
                            src={img6}
                            alt='pngwing.com.png'
                            width={100}
                          />
                          <img
                            loading='eager'
                            src={img7}
                            alt='pngwing.com.png'
                            width={100}
                          />
                          <img
                            loading='eager'
                            src={selectedImage}
                            alt='pngwing.com.png'
                            width={100}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class='col-md-3 d-flex justify-content-start  align-items-end flex-column coustom-top-size'>
                <div class='position-relative coutom-textnumber'>
                  <img
                    loading='eager'
                    src='assests/images/Asset-4.png'
                    class='user img-fluid'
                    alt='Asset 4'
                  />
                  <span class='position-absolute user-name game-username'>
                    FUN{user?.user_id}
                  </span>
                </div>
                <div class='mt-10 d-flex justify-content-end'>
                  <div class='position-relative'>
                    <img
                      loading='eager'
                      src={
                        timer <= 21 && timer >= 13 ? btnTimerBlink : btnTimer
                      }
                      class={
                        timer <= 21 && timer >= 13
                          ? 'timerBlink position-relative'
                          : 'timer position-relative'
                      }
                      alt='Timer'
                    />
                    <div class='timer-nmbr position-absolute'>{timer}</div>
                  </div>
                </div>
                <div
                  class='amusement'
                  style={{
                    zoom: '115%',
                  }}
                >
                  <p>For Amusement Only</p>
                </div>
                <div
                  class='game-point'
                  style={{
                    zoom: '115%',
                  }}
                >
                  <ul>
                    <li class='d-inline-block'>
                      {numbers[numbers.length - 10] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 9] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 8] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 7] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 6] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 5] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 4] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 3] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 2] ?? 0}{' '}
                    </li>

                    <li class='d-inline-block xxyz'>
                      {numbers[numbers.length - 1] ?? 0}{' '}
                    </li>
                  </ul>
                  <ul class='last-point mb-1'>
                    <li class='d-inline-block'>
                      <span style={{ fontSize: '18px' }}>
                        {numbers2[numbers2.length - 10] ?? 1}
                      </span>
                      <span style={{ fontSize: '13px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '18px' }}>
                        {' '}
                        {numbers2[numbers2.length - 9] ?? 1}
                      </span>
                      <span style={{ fontSize: '13px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '18px' }}>
                        {' '}
                        {numbers2[numbers2.length - 8] ?? 1}
                      </span>
                      <span style={{ fontSize: '13px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '18px' }}>
                        {' '}
                        {numbers2[numbers2.length - 7] ?? 1}
                      </span>
                      <span style={{ fontSize: '13px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '18px' }}>
                        {' '}
                        {numbers2[numbers2.length - 6] ?? 1}
                      </span>
                      <span style={{ fontSize: '13px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '18px' }}>
                        {' '}
                        {numbers2[numbers2.length - 5] ?? 1}
                      </span>
                      <span style={{ fontSize: '13px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '18px' }}>
                        {' '}
                        {numbers2[numbers2.length - 4] ?? 1}
                      </span>
                      <span style={{ fontSize: '13px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '18px' }}>
                        {' '}
                        {numbers2[numbers2.length - 3] ?? 1}
                      </span>
                      <span style={{ fontSize: '13px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '18px' }}>
                        {numbers2[numbers2.length - 2] ?? 1}
                      </span>
                      <span style={{ fontSize: '13px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '18px' }}>
                        {' '}
                        {numbers2[numbers2.length - 1] ?? 1}
                      </span>
                      <span style={{ fontSize: '13px' }}>x</span>
                    </li>
                  </ul>
                </div>
                <div class='prv-clear'>
                  <ul
                    style={
                      {
                        // paddingTop: '30px',
                      }
                    }
                  >
                    <li class='d-inline-block me-05'>
                      {clearclass === 'clr' ? (
                        <a class={clearclass}>PREV</a>
                      ) : (
                        <a onClick={prevDblBtn} href='#' class={clearclass}>
                          PREV
                        </a>
                      )}
                    </li>
                    <li class='d-inline-block me-05'>
                      {clearclass === 'clr' ? (
                        <a class='clr'>PREV</a>
                      ) : (
                        <a onClick={clearBtn} href='#' class='clr'>
                          CLEAR
                        </a>
                      )}
                    </li>
                    <li class='d-inline-block'>
                      {clearclass === 'clr' ? (
                        <a class={clearclass}>PREV</a>
                      ) : (
                        <a onClick={prevDblBtn} href='#' class={clearclass}>
                          DOUBLE
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class='row pt-10 d-xl-none'>
            <div class='col-md-12 text-center'>
              <div class='wheel position-relative'>
                {/* </div><img loading="eager" src="assests/images/Wheel-Final.gif" alt="Wheel-Final" class="wheel-img"/> */}
                {/* <img loading="eager" src="assests/images/Wheel-Final.gif" alt="Wheel-Final" class="wheel-img"> */}
                <div
                  style={{
                    ...spinn,
                    ...rotateStyle,
                  }}
                  class={`wheel-inner position-absolute `}
                >
                  <img
                    loading='eager'
                    src='assests/images/wheel-round2.png'
                    class='img-fluid'
                    alt='wheel-round'
                  />
                </div>

                <div class='wheel-middle position-absolute'>
                  <div style={{ bottom: '20px' }} class='position-relative'>
                    <img
                      loading='eager'
                      src='assests/images/Cent.png'
                      alt='Cent'
                      class='wheel-middle1'
                    />
                    <div
                      style={{
                        // backgroundColor: "red",
                        width: '100px',

                        overflow: 'hidden',
                      }}
                      class={`justify-content-end position-absolute money d-flex ${slideclass}`}
                    >
                      <span
                        className='d-flex'
                        style={{
                          height: '65px',
                        }}
                      >
                        <img
                          loading='eager'
                          src={img1}
                          alt='pngwing.com.png'
                          width={80}
                        />
                        <img
                          loading='eager'
                          src={img2}
                          alt='pngwing.com.png'
                          width={80}
                        />
                        <img
                          loading='eager'
                          src={img3}
                          alt='pngwing.com.png'
                          width={80}
                        />
                        <img
                          loading='eager'
                          src={img4}
                          alt='pngwing.com.png'
                          width={100}
                        />
                        <img
                          loading='eager'
                          src={img5}
                          alt='pngwing.com.png'
                          width={100}
                        />
                        <img
                          loading='eager'
                          src={img6}
                          alt='pngwing.com.png'
                          width={100}
                        />
                        <img
                          loading='eager'
                          src={img7}
                          alt='pngwing.com.png'
                          width={100}
                        />
                        <img
                          loading='eager'
                          src={selectedImage}
                          alt='pngwing.com.png'
                          width={100}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='col-md-12 mt-lg-400'>
              <ul class='mb-40 text-center text-xl-start'>
                <li class='d-inline-block me-45'>
                  <a href='#' onClick={() => setSelected(1)}>
                    <img
                      loading='eager'
                      style={selected === 1 ? style : {}}
                      src='assests/images/1-Ruppes-Coin.png'
                      alt='1 Ruppes 1 Ruppes Coin'
                      class='coin-img'
                    />
                  </a>
                </li>
                <li class='d-inline-block me-45'>
                  <a href='#' onClick={() => setSelected(5)}>
                    <img
                      loading='eager'
                      style={selected === 5 ? style : {}}
                      src='assests/images/5-Ruppes-coin.png'
                      alt='5 Ruppes coin'
                      class='coin-img'
                    />
                  </a>
                </li>
                <li class='d-inline-block'>
                  <a href='#' onClick={() => setSelected(10)}>
                    <img
                      loading='eager'
                      style={selected === 10 ? style : {}}
                      src='assests/images/10-Ruppes-Coin.png'
                      alt='10 Ruppes Coin.png'
                      class='coin-img'
                    />
                  </a>
                </li>
              </ul>
              <ul class='mb-40 text-center text-xl-start'>
                <li class='d-inline-block  me-45'>
                  <a href='#' onClick={() => setSelected(50)}>
                    <img
                      loading='eager'
                      style={selected === 50 ? style : {}}
                      src='assests/images/50-Ruppes-Coin.png'
                      alt='50 Ruppes Coin'
                      class='coin-img'
                    />
                  </a>
                </li>
                <li class='d-inline-block me-45'>
                  <a href='#' onClick={() => setSelected(100)}>
                    <img
                      loading='eager'
                      style={selected === 100 ? style : {}}
                      src='assests/images/100-Ruppes.png'
                      alt='100 Ruppes coin'
                      class='coin-img'
                    />
                  </a>
                </li>
                <li class='d-inline-block'>
                  <a href='#' onClick={() => setSelected(500)}>
                    <img
                      loading='eager'
                      style={selected === 500 ? style : {}}
                      src='assests/images/500-Ruppes-Coin.png'
                      alt='500 Ruppes Coin.png'
                      class='coin-img'
                    />
                  </a>
                </li>
              </ul>
              <ul class='text-center text-xl-start'>
                <li class='d-inline-block me-45'>
                  <a href='#' onClick={() => setSelected(1000)}>
                    <img
                      loading='eager'
                      style={selected === 1000 ? style : {}}
                      src='assests/images/1000-Ruppes-Coin.png'
                      alt='1000 Ruppes Coin'
                      class='coin-img'
                    />
                  </a>
                </li>
                <li class='d-inline-block me-45'>
                  <a href='#' onClick={() => setSelected(5000)}>
                    <img
                      loading='eager'
                      style={selected === 5000 ? style : {}}
                      src='assests/images/5000-Ruppes.png'
                      alt='5000 Ruppes coin'
                      class='coin-img'
                    />
                  </a>
                </li>
                <li class='d-inline-block'>
                  <a href='#' onClick={() => setSelected(0)}>
                    <img
                      loading='eager'
                      src='assests/images/Delete-Button-Logo.png'
                      alt='Delete Button Logo'
                      class='coin-img'
                    />
                  </a>
                </li>
              </ul>
              <ul class='blnc-bet-win mt-50 text-center text-xl-start'>
                <li class='d-inline-block'>
                  <a href='#' class='position-relative'>
                    <img
                      loading='eager'
                      src='assests/images/Asset-33.png'
                      alt='Asset 33'
                      class='img-fluid'
                    />
                    <span class='position-absolute amount'>0</span>
                    <span class='position-absolute amount-desc'>BALANCE</span>
                  </a>
                </li>
                <li class='d-inline-block'>
                  <a href='#' class='position-relative'>
                    <img
                      loading='eager'
                      src='assests/images/Asset-33.png'
                      alt='Asset 33'
                      class='img-fluid'
                    />
                    <span class='position-absolute amount'>0</span>
                    <span class='position-absolute amount-desc'>TOTAL BET</span>
                  </a>
                </li>
                <li class='d-inline-block mt-xsss-10'>
                  <a href='#' class='position-relative'>
                    <img
                      loading='eager'
                      src='assests/images/Asset-33.png'
                      alt='Asset 33'
                      class='img-fluid'
                    />
                    <span class='position-absolute amount'>0</span>
                    <span class='position-absolute amount-desc'>WINNING</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class='col-md-12 d-flex justify-content-start align-items-center  align-items-xl-end flex-column'>
              <div class='mt-sm-40 d-xl-flex user-points-amuse justify-content-center align-items-center d-none'>
                <div class=' position-relative me-lg-40 mt-lg-40 mt-sm-0'>
                  <img
                    loading='eager'
                    src='assests/images/Asset-4.png'
                    class='user img-fluid'
                    alt='Asset 4'
                  />
                  <span class='position-absolute user-name game-username'>
                    FUN021005048
                  </span>
                </div>
                <div class='mt-10 d-flex justify-content-end me-lg-40 mt-lg-40 mt-sm-0'>
                  <div class='position-relative'>
                    <img
                      loading='eager'
                      src='assests/images/Timer.png'
                      class='timer position-relative'
                      alt='Timer'
                    />
                    <div class='timer-nmbr position-absolute'>32</div>
                  </div>
                </div>
                <div class='amusement'>
                  <p>For Amusement Only</p>
                </div>
              </div>
              <div class='user-points-amuse1 display-none'>
                <div class='d-flex justify-content-between align-items-center mt-30 mt-xsss-15'>
                  <div class=' position-relative me-lg-40 mt-lg-40 mt-sm-0'>
                    <img
                      loading='eager'
                      src='assests/images/Asset-4.png'
                      class='user img-fluid'
                      alt='Asset 4'
                    />
                    <span class='position-absolute user-name game-username'>
                      FUN021005048
                    </span>
                  </div>
                  <div class='mt-10 d-flex justify-content-end me-xss-0 me-lg-40 mt-lg-40 mt-sm-0'>
                    <div class='position-relative'>
                      <img
                        loading='eager'
                        src={
                          timer <= 21 && timer >= 13 ? btnTimerBlink : btnTimer
                        }
                        class={
                          timer <= 21 && timer >= 13
                            ? 'timerBlink position-relative'
                            : 'timer position-relative'
                        }
                        alt='Timer'
                      />
                      <div class='timer-nmbr position-absolute'>{timer}</div>
                    </div>
                  </div>
                </div>
                <div class='amusement mt-lg-40 mx-auto'>
                  <p>For Amusement Only</p>
                </div>
              </div>
              <div class='d-flex mt-md-20 justify-content-between align-items-center flex-column'>
                <div class='game-point'>
                  <ul>
                    <li class='d-inline-block'>
                      {numbers[numbers.length - 10] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 9] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 8] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 7] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 6] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 5] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 4] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 3] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 2] ?? 0}
                    </li>

                    <li class='d-inline-block'>
                      {numbers[numbers.length - 1] ?? 0}
                    </li>
                  </ul>
                  <ul class='last-point mb-10'>
                    <li class='d-inline-block'>
                      <span style={{ fontSize: '14px' }}>
                        {numbers2[numbers2.length - 10] ?? 1}
                      </span>
                      <span style={{ fontSize: '10px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '14px' }}>
                        {' '}
                        {numbers2[numbers2.length - 9] ?? 1}
                      </span>
                      <span style={{ fontSize: '10px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '14px' }}>
                        {' '}
                        {numbers2[numbers2.length - 8] ?? 1}
                      </span>
                      <span style={{ fontSize: '10px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '14px' }}>
                        {' '}
                        {numbers2[numbers2.length - 7] ?? 1}
                      </span>
                      <span style={{ fontSize: '10px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '14px' }}>
                        {' '}
                        {numbers2[numbers2.length - 6] ?? 1}
                      </span>
                      <span style={{ fontSize: '10px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '14px' }}>
                        {' '}
                        {numbers2[numbers2.length - 5] ?? 1}
                      </span>
                      <span style={{ fontSize: '10px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '14px' }}>
                        {' '}
                        {numbers2[numbers2.length - 4] ?? 1}
                      </span>
                      <span style={{ fontSize: '10px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '14px' }}>
                        {' '}
                        {numbers2[numbers2.length - 3] ?? 1}
                      </span>
                      <span style={{ fontSize: '10px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '14px' }}>
                        {numbers2[numbers2.length - 2] ?? 1}
                      </span>
                      <span style={{ fontSize: '10px' }}>x</span>
                    </li>

                    <li class='d-inline-block'>
                      <span style={{ fontSize: '14px' }}>
                        {' '}
                        {numbers2[numbers2.length - 1] ?? 1}
                      </span>
                      <span style={{ fontSize: '10px' }}>x</span>
                    </li>
                  </ul>
                </div>
                <div class='prv-clear mt-md-20'>
                  <ul>
                    <li class='d-inline-block me-05'>
                      <a href='#' class={clearclass}>
                        PREV
                      </a>
                    </li>
                    <li class='d-inline-block me-05'>
                      <a href='#' class='clr'>
                        CLEAR
                      </a>
                    </li>
                    <li class='d-inline-block'>
                      <a href='#' className={clearclass}>
                        DOUBLE
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class='row nmbr-button mt-md-20'>
            <div class='col-sm-2 col-nmbr-4'>
              <a href='#'>
                <img
                  loading='eager'
                  src='assests/images/1-Number-Button-logo.png'
                  alt='1 Number Button logo'
                  class='img-fluid'
                />
              </a>
            </div>
            <div class='col-sm-2 col-nmbr-4'>
              <a href='#'>
                <img
                  loading='eager'
                  src='assests/images/2-Number-Button-logo.png'
                  alt='2 Number Button logo'
                  class='img-fluid'
                />
              </a>
            </div>
            <div class='col-sm-2 col-nmbr-4'>
              <a href='#'>
                <img
                  loading='eager'
                  src='assests/images/3-Number-Button-logo.png'
                  alt='3 Number Button logo'
                  class='img-fluid'
                />
              </a>
            </div>
            <div class='col-sm-2 col-nmbr-4'>
              <a href='#'>
                <img
                  loading='eager'
                  src='assests/images/4-Number-Button-logo.png'
                  alt='4 Number Button logo'
                  class='img-fluid'
                />
              </a>
            </div>
            <div class='col-sm-2 col-nmbr-4'>
              <a href='#'>
                <img
                  loading='eager'
                  src='assests/images/5-Number-Button-logo.png'
                  alt='5 Number Button logo'
                  class='img-fluid'
                />
              </a>
            </div>
            <div class='col-sm-2 col-nmbr-4'>
              <img
                loading='eager'
                src='assests/images/6-Number-Button-logo.png'
                alt='6 Number Button logo'
                class='img-fluid'
              />
            </div>
            <div class='col-sm-2 col-nmbr-4'>
              <a href='#'>
                <img
                  loading='eager'
                  src='assests/images/7-Number-Button-logo.png'
                  alt='7 Number Button logo'
                  class='img-fluid'
                />
              </a>
            </div>
            <div class='col-sm-2 col-nmbr-4'>
              <a href='#'>
                <img
                  loading='eager'
                  src='assests/images/8-Number-Button-logo.png'
                  alt='8 Number Button logo'
                  class='img-fluid'
                />
              </a>
            </div>
            <div class='col-sm-2 col-nmbr-4'>
              <a href='#'>
                <img
                  loading='eager'
                  src='assests/images/9-Number-Button-logo.png'
                  alt='9 Number Button logo'
                  class='img-fluid'
                />
              </a>
            </div>
            <div class='col-sm-2 col-nmbr-4 mx-auto mx-sm-none'>
              <a href='#'>
                <img
                  loading='eager'
                  src='assests/images/0-Number-Button-logo.png'
                  alt='0 Number Button logo'
                  class='img-fluid'
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Game;
