import React from 'react';

const Input = (props) => {
  const { label, type, name, value, required, style, onChange } = props;
  return (
    <div class='row d-flex login-content align-items-center mb-20 mb-xs-0'>
      <label class='user col-3'>{label}</label>
      <span class='col-11'>
        {type === 'text' && (
          <span style={{ position: 'relative', left: '50px' }}>FUN</span>
        )}
        {type === 'password' && (
          <span style={{ position: 'relative', left: '50px', opacity: '0' }}>
            FUN
          </span>
        )}
        <input
          onChange={onChange}
          style={style}
          type={type}
          name={name}
          value={value}
          required={required}
        />
      </span>
      <br />
    </div>
  );
};

export default Input;
