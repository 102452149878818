import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux/es/exports";
import BrowserRoutes from "../../routes/browserRoutes";
const AuthRoute = ({ component: Component }) => {
  const isAuthenticated = useSelector((state) => state.auth.loggedIn);
  if (!isAuthenticated) return <Component />;
  else {
    return <Navigate to={BrowserRoutes.ACCOUNT} />;
  }
};

export default AuthRoute;
